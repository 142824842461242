import Footer from "../components/Footer";

export default function ContactUs() {
  return (
    <div>
      <section className="contact-intro">
        <h1 className="h-contact">Contact<span className="color-b"> Us</span></h1>
        <p className="p-contact">Contact us for an estimates or questions about your project! Fill out the form and we <br/>
        will get back to you in 24 hours.</p>
      </section>

      <section className="contact-container">
        <div className="message-container">
          <form className="email-form">
            <input type="text" name="name" className="form-control" placeholder="Your Name" required />
            <input name="phone" type="phone" className="form-control" placeholder="Phone" required />
            <input name="email" type="email" className="form-control" placeholder="Your Email" required />
            <input type="text" name="subject" className="form-control" placeholder="Subject" required />
            <textarea name="message" className="form-control" cols="45" rows="8" placeholder="Message" required></textarea>
            <button type="submit" className="btn-submit">Send Message</button>
          </form>
        </div>
      </section>
      <Footer />
    </div>  
  );
}

