import React from 'react'
import Team from "../img/team.png"
import TechSlider from "./TechSlider"
import Icon1 from '../img/icon-1.png';
import Icon2 from '../img/icon-2.png';
import Icon3 from '../img/icon-s-2.png';
import Icon33 from '../img/icon-s-3.png';
import Icon4 from '../img/icon-s-4.png';
import Icon5 from '../img/icon-s-5.png';
import Icon6 from '../img/icon-s-6.png';
import Icon7 from '../img/icon-s-7.png';
import Icon8 from '../img/icon-s-8.png';
import Icon9 from '../img/icon-s-11.png';
import reactjs from '../img/react-js.png';
import python from '../img/python.png';
import shopify from '../img/shopify.png';
import nodejs from '../img/nodejs.png';
import mysql from '../img/mysql.png';
import php from '../img/php.png';
import mongodb from '../img/mongodb.png';
import selenium from '../img/selenium.png';
import puppet from '../img/puppet.png';
import laravel from '../img/laravel.png';
import codeigniter from '../img/laravel.png';
const TeamAndTech = () => {
    const  slider1Images= [reactjs,mysql,python, mongodb, php, shopify, puppet, nodejs, Icon6, selenium, Icon7, codeigniter, Icon9]
    const  slider2Images= [reactjs,Icon1,Icon2, Icon3, shopify, Icon33, nodejs, Icon4, Icon5, Icon6, Icon7, Icon8, python, Icon9]
  return (
    <div>
      <div className="services-section">
        <div className="container-fluid p-md-5 py-5">
          <div className="row">
            <div className="col-lg-6"  >
              <h2 className="text-white fw-bold">Explore Our <span>Pre-Vetted Team</span> and Our Diverse <span>Tech Stack</span></h2>
              <p>We cater to a diverse range of industries and ensure to connect our clients with the right talent that is seasoned in the relevant tech stack they require, eliminating the challenges of the tedious recruitment process. Experience a hassle-free hiring process with us.</p>
              <div className="tech mt-5">
                <div class="fw-semibold text-white my-5 h1">Our Tech Stacks</div>
                <TechSlider slider1Images={slider1Images} direction={false} />
                <TechSlider slider1Images={slider2Images} direction={true} />
              </div>
            </div>
            <div className="col-lg-6 px-md-5">
              <div className="px-md-5 d-flex flex-column justify-content-center align-items-center">
                <div class="  mt-3 h1 fw-bold text-white">Top <span>Talent</span></div>
                <img alt="image" loading="lazy" decoding="async" data-nimg="fill" className=" p-md-5 TeamImage" src={Team} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TeamAndTech
