import Node from "../img/nodejs.png"
import Django from "../img/django.png"
import Python from "../img/python.png"
import Laravel from "../img/laravel.png"
import Flutter from "../img/flutter.png"
import React from "../img/react-js.png"
import Shopify from "../img/shopify.png"
import Wordpress from "../img/wordpress.png"
import Footer from "../components/Footer";
import Header from "../components/AboutSection"
import Service from "../components/ServicesSection"

export default function OurWork() {
  return (
    <div>
      <Header />
      <div className="tech-container">
        <h2 className='service-h'><span className="color-b">Technologies</span></h2>
        <div className="technologies">
          <img alt="web icon" src={React} />
          <img alt="web icon" src={Python} />
          <img alt="web icon" src={Laravel} />
          <img alt="web icon" src={Django} />
          <img alt="web icon" src={Node} />
          <img alt="web icon" src={Flutter} />
          <img alt="web icon" src={Shopify} />
          <img alt="web icon" src={Wordpress} />
        </div>
      </div>
      {/* <Service /> */}
      <Footer />
    </div> 
  );
}


