import React from 'react'
import { Link } from 'react-router-dom'

const ContactHere = () => {
    return (
        <div className='contact-container section-bg-secondary py-5'>
            <div className="p-md-5">
                <div class="topInner py-3 px-5 mb-5 text-white">
                    <div class="h1 fw-bold display-4">
                        <span>Let's Bring Innovation</span>
                    </div>
                    <div>Share your idea with us, and let's come up with something innovative.</div>
                </div>
                <div className="row ">

                    <div className="col-lg-6 px-5">
                        <div className="formBg">
                            <form className='p-md-5 px-3 py-5 '>
                                <div class="form-group mb-5 ">
                                    <input type="email" class="form-control p-3 contact-input rounded-pill" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
                                </div>
                                <div class="form-group mb-5 ">
                                    <input type="text" class="form-control p-3 contact-input rounded-pill" id="exampleInputPassword1" placeholder="Your Name" />
                                </div>

                                <div class="form-group mb-5 ">
                                    <textarea class="form-control p-3 contact-input rounded" id="exampleFormControlTextarea1" rows="3"></textarea>
                                </div>
                                <button type="submit" class="submitBtn">Submit</button>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-6 px-5">
                        <div class="innerConRtS innerConRtSContUs">
                            <div class="contactDetail d-flex justify-content-start gap-5 text-white mt-5">
                                <div class="conIcon">
                                    <img src="https://atrule.s3.us-east-2.amazonaws.com/public/assets/images/inerconEmail.png" alt="" />
                                </div>
                                <div class="conIcon">
                                    <div className='h3 fw-bold'><span>Email </span></div>
                                    <div class="innetNum mt-1">
                                        <a className='text-decoration-none text-white' href="#">info@codeblue.agency</a></div>
                                </div></div>
                            <div class=" d-flex justify-content-start gap-5 text-white mt-5">
                                <div class="conIcon">
                                    <img src="https://atrule.s3.us-east-2.amazonaws.com/public/assets/images/inerconPhone.png" alt="" />
                                </div>
                                <div class="conIcon">
                                    <div className='h3 fw-bold'><span>Phone </span></div>
                                    <div class="innetNum mt-1">+92300 5072288</div>
                                    {/* <div class="innetNum">+92300 5072288</div> */}
                                </div></div>
                            <div class=" d-flex justify-content-start gap-5 text-white mt-5">
                                <div class="conIcon">
                                    <img src="https://atrule.s3.us-east-2.amazonaws.com/public/assets/images/inerconMap.png" alt="" />
                                </div>
                                <div class="conIcon">
                                    <div className='h3 fw-bold'> <span>Location </span></div>
                                    <div class="innetNum mt-1">
                                        <a className='text-decoration-none text-white' href="#">CodeBlue beside Allied Bank,45-A,Gulgasht Colony,Multan</a>
                                    </div>
                                </div>
                            </div>
                            <div class=" d-flex justify-content-start gap-5 text-white mt-5">
                                <div class="conIcon">
                                    <img src="https://atrule.s3.us-east-2.amazonaws.com/public/assets/images/se-time.png" alt="" />
                                </div>
                                <div class="conIcon">
                                    <div className='h3 fw-bold'><span>Hour Of Operation  </span></div>
                                    <div class="innetNum">
                                        <a className='text-decoration-none text-white' href="#">Monday - Saturday: 09:00 - 5:00</a>
                                    </div>
                                    <div class="innetNum mt-2">
                                        <a className='text-decoration-none text-white' href="#">Sunday: Off</a>
                                    </div></div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}

export default ContactHere
