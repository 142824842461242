import styled from "styled-components";
import { Link } from "react-router-dom";
import Logo from '../img/logo.png'
export default function Nav() {
  return (
    <StyledNav className=" py-4 py-md-0">
        <Link id="logo"  to="/">
          <img className="w-100 h-100" src={Logo} alt='Codeblue' />
        </Link>
      <ul>
        <li>
          <a id="nav-link" href="#">Home</a>
        </li>
        <li>
          <a id="nav-link" href="#ServicesSection">Services</a>
        </li>
        <li>
          <a id="nav-link" href="#ContactSection">Contact Us</a>
        </li>
      </ul>
    </StyledNav>
  );
}

const StyledNav = styled.nav`
  position: sticky;
  top: 0;
  left: 0;
  min-height: 10vh;
  display: flex;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 10rem;
  background: #1B1B1B;
  z-index: 3;
  a {
    color: white;
    text-decoration: none;
  }
  ul {
    display: flex;
    list-style: none;
  }
  li {
    position: relative;
    & + li {
      padding-left: 5rem;
    }
  }
  
  #nav-link {
    color: #ffff;
    font-size: 1.5rem;
    transition: background-color 0.3s ease, color 0.3s ease;
    position: relative; 
  }
  #nav-link::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -3px; 
    left: 0;
    width: 100%;
    height: 2px; 
    background-color: #00BFFF;
    transform: scaleX(0); 
    transition: transform 0.3s ease; 
  }
  #nav-link:hover::after {
    transform: scaleX(1); 
  }
  @media screen and (max-width: 700px) {
    position: relative;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;
    ul {
      padding: 1rem 0;
      justify-content: center;
      width: 100vw;
      li {
        background: #555;
        border-radius: 15px;
        padding: 1rem;
        text-align: center;
        & + li {
          margin-left: 1rem;
        }
      }
    }
  }
`;
