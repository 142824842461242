import styled from "styled-components";
import { About } from "../styles";
import Toggle from "../components/Toggle";
import { AnimateSharedLayout } from "framer-motion";
import { useState } from "react";
export default function FaqSection() {
  // const [element, controls] = useScroll();
  const [toggle1, setToggle1] = useState(false);
  const [toggle2, setToggle2] = useState(true);
  const [toggle3, setToggle3] = useState(true);
  return (
    <div className=" faq-section px-md-0 px-5">
      <Faq
      >
        <h2 className=" fs-1 text-white fw-bold my-5 text-center">
          Frequently Asked <span>Questions</span>
        </h2>
        <AnimateSharedLayout>
          <Toggle toggle={toggle1} setToggle={setToggle1} title="How Do I Start?">
            <div className="answer">

              To embark on a successful project, it's crucial to begin by thoroughly assessing the project requirements and scope. Understanding the intricacies of what needs to be accomplished sets the foundation for a well-executed plan. Following this, the creation of a comprehensive project timeline and milestones becomes paramount. These milestones act as guideposts, helping to track progress and ensure that the project stays on course.
              <br />
              <br />
              Once the roadmap is in place, it's time to dive into development and code implementation. This phase demands a keen attention to detail, as each line of code contributes to the overall functionality of the project. Simultaneously, a continuous feedback loop should be established to facilitate collaboration with other departments and stakeholders. Open communication ensures that everyone is aligned with the project's goals and can provide valuable insights throughout the development process.
              <br />
              <br />

              As the code takes shape, it's essential to periodically review and optimize it for performance. This proactive approach helps identify and address potential issues early on, preventing them from escalating into major challenges later. Collaboration remains key during this optimization phase, as different perspectives can shed light on areas for improvement.
              <br />
              <br />

              Even after the initial development is complete, the journey doesn't end there. Ongoing support and maintenance are integral components of a project's lifecycle. This ensures that the implemented solution remains robust, adaptable, and capable of meeting evolving needs. Regular updates, bug fixes, and user support contribute to the longevity and success of the project, creating a dynamic cycle of improvement and innovation.
              <br />
              <br />
            </div>
          </Toggle>
          <Toggle toggle={toggle2} setToggle={setToggle2} title="What types of technologies does CodeBue support?">
            <div className="answer">

              CodeBlue is a versatile platform supporting a range of technologies, including web, desktop, and Android applications. It seamlessly integrates with web technologies, ensuring a robust online presence. For desktop environments, CodeBlue facilitates optimal performance and integration. On the mobile front, it extends support to Android, empowering developers to create innovative mobile applications. Additionally, CodeBlue is adept at handling PHP, Python, and JavaScript, providing a flexible coding environment. With this broad spectrum of technology support, CodeBlue proves to be a comprehensive solution for diverse development needs.
            </div>
          </Toggle>
          <Toggle toggle={toggle3} setToggle={setToggle3} title="How many industries does CodeBlue cater to?">
            <div className="answer">
              CodeBlue is a versatile solution that extends its support across various industries, including business, eCommerce, and IT services.
              In the dynamic landscape of business operations, CodeBlue offers tailored services to enhance efficiency and streamline processes.
              In the ever-evolving eCommerce industry, it provides robust solutions to facilitate seamless online transactions and optimize digital storefronts.
              Additionally, CodeBlue plays a pivotal role in the realm of IT services, addressing the unique technological needs of businesses and ensuring a secure and optimized digital infrastructure. By catering to these diverse sectors,
              CodeBlue demonstrates its adaptability and commitment to driving success across a spectrum of industries.
            </div>
          </Toggle>
        </AnimateSharedLayout>
      </Faq>
    </div>
  );
}

const Faq = styled(About)`
  display: block;
  
  h2 {
    padding-bottom: 2rem;
    font-weight: lighter;
  }
  .line {
    display: block;
    background: #ccc;
    height: 0.1rem;
    margin: 2rem 0rem;
    width: 100%;
  }
  .question {
    padding: 2rem 0rem;
    cursor: pointer;
  }
  .answer {
    padding: 2rem 0rem;
    font-size:15px !important;
    p {
      padding: 1rem 0rem;
    }
  }
`;
