import React from 'react';
import { Link } from "react-router-dom";
import Logo from '../img/logo.png'
import { SiPayoneer } from "react-icons/si";


export default function Footer() {
  return (
    <div className=''>
      <section className="section-footer section-bg-secondary">
        <div className="container-row">

          <div className="intro-info">
            <Link id="logo" to="/">
              <img className="w-100 h-100" src={Logo} alt='Codeblue' />
            </Link>
            <p className="p-0">
              CodeBlue is a tight-knit startup that builds,<br></br> designs and delivers top services to <br></br> enterprises alongwith in-house <br></br>experienced developers.
            </p>
            <span className='fs-5 '>How we Get Paid?</span>
            <div className="payments my-3 d-flex  align-items-center gap-2 ">
              <div className="text-white p-3 rounded" style={{backgroundColor:'#F74600'}}>
              <SiPayoneer className='fs-5'/>
              </div>
              <div className="payment-option text-white">
                <div className="clientId"><span>Clinet ID: </span>#24254877</div>
                <a className='text-decoration-none text-white' href='mailto:usmanaltaf1@gmail.com'><span>Email: </span>usmanaltaf1@gmail.com</a>
              </div>
            </div>
          </div>
          <div className="company-info">
            <h3 className='h3 main-heading'><span>Company</span></h3>
            <ul>
              <li className="item-list-a">
                <a href='#'>Home</a>
              </li>
              <li className="item-list-a">
                <a href='#ServicesSection'>What we do</a>
              </li>
              <li className="item-list-a">
                <a href='#ContactSection'>Contact Us</a>
              </li>
            </ul>
          </div>
          <div className="service">
            <h3 className='h3 main-heading'><span>Services</span></h3>
            <ul>
              <li className="item-list-a">
                <a>Web Development</a>
              </li>
              <li className="item-list-a">
                <a>Mobile Development</a>
              </li>
              <li className="item-list-a">
                <a>Desktop Development</a>
              </li>
              
            </ul>
          </div>
          <div className="location">
            <h3 className='main-heading '><span>Location</span></h3>
            <div className="item-list-a" style={{color:'white',padding:'20px 0',fontSize:'15px'}}>
              CodeBlue beside Allied Bank,45-A <br></br>Gulgasht Colony,Multan
            </div>
            <ul>
              <li className="color-w">
                <span className="color-b">Email:</span>  info@codeblue.agency
              </li>
            </ul>
          </div>
        </div>
        {/* <div className="socials-a">
          <ul>
            <li className="list-inline-item">
              <a href="#">
                <i className="fab fa-facebook" aria-hidden="true"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="#">
                <i className="fab fa-twitter" aria-hidden="true"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="#">
                <i className="fab fa-instagram" aria-hidden="true"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="#">
                <i className="fab fa-linkedin" aria-hidden="true"></i>
              </a>
            </li>
          </ul>
        </div> */}
        <div className="copyright-footer text-center">
          <p>
            &copy; Copyright Code
            <span className="color-b">Blue</span> All Rights Reserved.
          </p>
        </div>
      </section>

    </div>
  );
}

