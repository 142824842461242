import React from "../img/react-js.png"
import { MdDeveloperMode } from "react-icons/md";
import TeamAndTech from "./TeamAndTech";
import { PiDesktopTowerBold } from "react-icons/pi";
import { LiaRobotSolid } from "react-icons/lia";
import { MdOutlineShoppingCart } from "react-icons/md";
import { FaShopify } from "react-icons/fa";
import { GoDesktopDownload } from "react-icons/go";

const ServicesSection = () => {
  const services = [
    {
      name: "Web Development",
      image: <PiDesktopTowerBold />,
      description: "Our team of skilled developers is ready to create secure and cutting-edge web applications that run seamlessly on any device, browser, and operating system. Elevate your online presence and user experience with our expert web development services."
    },
    {
      name: "App Development",
      image: <MdDeveloperMode/>,
      description: "Welcome to our app development services! We craft innovative and user-friendly mobile and web applications, custom-built or modified to suit your needs. Our expertise allows us to build custom apps from the ground up, tailored to your company's specific needs. Embrace the future with us!"
    },
    {
      name: "Desktop Development",
      image: <GoDesktopDownload/>,
      description: "Discover the power of our desktop development services, where we create custom, user-friendly applications to suit your company's needs. From building new apps to enhancing existing ones, our skilled team has you covered with efficient and innovative solutions. Elevate your business with our seamless desktop development expertise."
    },
    {
      name: "Ecommerce Development",
      image: <MdOutlineShoppingCart/>,
      description: "Explore our top-notch eCommerce development services with a team of skilled app developers. Transform your eCommerce vision into a seamless and efficient online shopping experience that caters to your unique needs. Let's make it happen!"
    },
    {
      name: "Shopify Development",
      image: <FaShopify/>,
      description: "Explore our top-notch Shopify development services with a team of skilled app developers. Transform your eCommerce vision into a seamless and efficient online shopping experience that caters to your unique needs. Let's make it happen!"
    },
    {
      name: "Auto Bots Development",
      image: <LiaRobotSolid/>,
      description: "Explore our top-notch eCommerce development services with a team of skilled app developers. Transform your eCommerce vision into a seamless and efficient online shopping experience that caters to your unique needs. Let's make it happen!"
    },
  ]

  return (
    <div>

      <TeamAndTech />
      <section id="ServicesSection" class="services-sectionn padding my-5 ">
        <h2 className=" text-white fw-bold my-5 text-center">Our <span className="color-b">Services</span></h2>
        <div class="container my-5 py-3">
          <div className="row">
            {
              services.map((service, index) => (
                <div key={index} className="col-lg-4 col-md-6 mb-4">
                  <div className="card">
                    <div className="service-1">
                      <div className="service-up">
                        {service.image}
                        <h3>{service.name}</h3>
                      </div>
                      <p>{service.description}</p>
                    </div>
                  </div>
                </div>
              ))
            }


          </div>

        </div>
      </section>
    </div>
  )
}

export default ServicesSection
