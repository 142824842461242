import Wave from "./Wave";
import { Link } from "react-router-dom";

//Styled-Components
import { About, Description, Hide } from "../styles";
//Animation
import { titleAnimation, fade } from "../animation";
import { motion } from "framer-motion";
import { useRef } from "react";
import SliderImage from '../img/slider.png'
export default function AboutSection() {
  const contact = useRef();
  return (
    <About className="py-5 my-5 my-md-0">
      <div className="row">
        <div className="col-lg-6">
          <Description className="text-capitalize">
            <motion.div>
              <Hide>
                <motion.h2 className="h1 display-1 fw-bolder" variants={titleAnimation}>We <span> work</span> to make</motion.h2>
              </Hide>
              <Hide>
                <motion.h2 className="h1 display-2  fw-bold" variants={titleAnimation}>
                  your <span>dreams</span> come
                </motion.h2>
              </Hide>
              <Hide>
                <motion.h2 className="h1 display-2 fw-bold" variants={titleAnimation}>true.</motion.h2>
              </Hide>
            </motion.div>
            <motion.p className="p" variants={fade}>
              We are experienced software developers  who can help <br /> with all your  software development needs. <br /> Contact us to learn more.
            </motion.p>
            <motion.button variants={fade}>
              <a
                href="#ContactSection"
                style={{ color: "white", textDecoration: "none" }}

              >
                Contact Us
              </a>
            </motion.button>
          </Description>
        </div>
        <div className="col-lg-6  d-flex justify-content-center align-items-center">
          <img className="aboutImage" src={SliderImage} alt="" />
        </div>
      </div>
      <Wave />
    </About>
  );
}
