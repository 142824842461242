import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TechSlider = ({direction,slider1Images}) => {
    
    var settings = {
        dots: false,
        infinite: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 1000,
        speed: 500,
        slidesToShow: 6,
        initialSlide: 0,
        slidesToScroll: 1, 
        rtl:direction,
        pauseOnHover:false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    // dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            }
        ]
    }
    return (
        <div className='my-5'>
            <Slider {...settings}>
                {
                    slider1Images?.map((image, index) => (
                        <div key={index}>
                            <img className='slideSkillImages' src={image} style={{ width: '80px', borderRadius: '50%' }} alt={`icon${index + 1}`} />
                        </div>
                    ))
                }
            </Slider>
        </div>
    )
}

export default TechSlider
