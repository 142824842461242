import { useState } from "react";
import { motion } from "framer-motion";

export default function Toggle({ children, title,toggle,setToggle }) {
  
  return (
    <motion.div layout  onClick={() => setToggle(!toggle)}>
      <motion.h4 className="h4 fw-bold" style={{color:'#00BFFF',cursor:'pointer'}} layout>{title}</motion.h4>
      {toggle ? "" : children}
      <div className="line"></div>
    </motion.div>
  );
}
