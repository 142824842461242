import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    @media screen and (max-width: 1700px) {
      font-size:75%;
    }
  }

  body {
    background: #1b1b1b;
    font-family: 'Inter', sans-serif;
  }

  .padding{
    padding:0px 70px;
  }

  button {
    cursor: pointer;
    font-weight: bold;
    font-size: 1.1rem;
    padding: 1rem 2rem;
    border: 3px solid #00BFFF;
    background: transparent;
    color: white;
    transition: all 0.5s ease;
    font-family: 'Inter', sans-serif;
    &:hover {
      background: #00BFFF;
      color: #1b1b1b;
    }
  }

  h2 {
    font-weight: lighter;
    font-size: 4rem;
  }

  h3 {
    color: white;
  }

  h4 {
    font-weight: bold;
    font-size: 2rem;
  }

  a {
    font-size: 1.1rem;
  }

  span {
    font-weight: bold;
    color: #00BFFF;
  }

  p {
    padding: 3rem 0rem;
    color: #ccc;
    font-size: 1.4rem;
    line-height: 150%;
  }

  .section-bg-secondary{
    background-color:#2E2E2E;
  }
`;

export default GlobalStyle;
