import AboutSection from "../components/AboutSection";
import ServicesSection from "../components/ServicesSection";
import FaqSection from "../components/FaqSection";
import Footer from "../components/Footer";

import { motion } from "framer-motion";
import { pageAnimation } from "../animation";
import ContactHere from "../components/ContactHere";

export default function AboutUs() {
  return (
    <motion.div
      style={{ overflow: "hidden" }}
      exit="exit"
      variants={pageAnimation}
      initial="hidden"
      animate="show"
    >
      <AboutSection />
      <ServicesSection />
      <div id="ContactSection" >
        <ContactHere />
      </div>
      <FaqSection />
      <Footer />
    </motion.div>
  );
}
